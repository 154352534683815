import { useCallback, useState } from "react";
import axios from "axios";
import { useAuth } from "./auth.hook";


export const GetHeaders = (isMultipart = false, withoutToken = false, rkfToken = '') => {
    let token = '';

    if (!withoutToken) {
        token = JSON.parse(localStorage.getItem('token'));
    }

    if(rkfToken) {
        token = rkfToken;
    }

    let headers = {};

    headers["Accept"] = "application/json";
    headers["Cache-Control"] = "no-cache";
    headers["Content-Type"] = isMultipart ? "multipart/form-data" : "application/json, text/plain, */*";

    if (token) headers["Authorization"] = "Bearer " + token;

    return headers;
};

export const useRequest = () => {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const {logout} = useAuth();

    const request = useCallback(async (options, onSuccess, onError) => {
        setLoading(true);

        const axiosConfig = {
            ...options,
            headers: GetHeaders(options.isMultipart, options.withoutToken, options.rkfToken)
        };

        try {
            const response = await axios(axiosConfig);

            setLoading(false);

            if (onSuccess) onSuccess(response.data.result);

            return response.data.result;
        } catch (e) {
            setLoading(false);

            if (e.response.status === 401) {
                logout();
                window.location.reload();
            }

            if (onError) onError(e);

            if (e.isAxiosError && e.response) {
                if (e.response.data.errors) {
                    setErrors(Object.keys(e.response.data.errors).map(key => `Ошибка ${key}: ${e.response.data.errors[key]}`));
                } else {
                    setErrors([`Ошибка ${e.response.status}: ${e.response.statusText}`]);
                }
            } else {
                setErrors(['Что-то пошло не так...']);
            }
        }
    }, [logout]);

    const clearError = useCallback(() => setErrors([]), []);

    return { loading, errors, request, clearError };
};