import * as LoadableModules from "../../appModules";


export const widgetsRoutes = [
    {
        exact: true,
        path: '/widgets/registration-for-service',
        component: LoadableModules.LoadableRegistrationForService
    },
    {
        exact: true,
        path: '/unsubscribe/success',
        component: LoadableModules.LoadableUnsubscribeSuccess
    },
    {
        exact: true,
        path: '/unsubscribe/error',
        component: LoadableModules.LoadableUnsubscribeError
    }
];