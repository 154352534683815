import React, {useCallback} from "react";
import ReactDOM from "react-dom";
import CustomConfirm from "../components/Confirm";


export const useConfirm = () => {
    const confirm = useCallback((confirmation, title) => {
        const wrapper = document.body.appendChild(document.createElement('div'));

        const promise = new Promise((resolve, reject) => {
            try {
                ReactDOM.render(
                    <CustomConfirm
                        open={true}
                        title={title}
                        confirmation={confirmation}
                        onSuccess={() => resolve(true)}
                        onCancel={() => reject(false)}
                    />,
                    wrapper
                );
            } catch (e) {
                console.error(e);
                throw e;
            }
        });

        return promise.then(result => {
            ReactDOM.unmountComponentAtNode(wrapper)
            return result;
        }, result => {
            ReactDOM.unmountComponentAtNode(wrapper)
            return result;
        });
    }, []);

    return {confirm};
};