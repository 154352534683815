import React, {memo} from "react";
import {Button} from "@material-ui/core";
import "./index.scss";

//bgColor: grey, red, outline

const CustomButton = ({className, bgColor, disabled = false, onClick, children, ...props}) => (
    <Button
        {...props}
        className={`custom-button${bgColor ? ' _' + bgColor : ''}${className ? ' ' + className : ''}`}
        variant="contained"
        disabled={disabled}
        onClick={onClick}
    >
        {children}
    </Button>
);

export default memo(CustomButton);