import React, { memo, useState } from "react";
import { DEFAULT_AVATAR } from "../../../../../appConfig";
import { Icon } from "@material-ui/core";
import Modal from "../../../../../components/Modal";
import LightTooltip from "../../../../../components/LigthTooltip";
import CustomAvatarEditor from "./AvatarEditor";


const EditAvatar = ({ avatar }) => {
    const [openModal, setOpenModal] = useState(false);

    return (
        <div className="sidebar__user-avatar edit-avatar">
            <img className="edit-avatar__img" src={avatar || DEFAULT_AVATAR} alt="avatar" />
            <div className="edit-avatar__icon-wrap" onClick={() => setOpenModal(true)}>
                <LightTooltip title="Редактировать">
                    <Icon className="edit-avatar__icon">edit</Icon>
                </LightTooltip>
            </div>
            <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
                title="Редактирование аватара"
            >
                <CustomAvatarEditor avatar={avatar} closeModal={() => setOpenModal(false)} />
            </Modal>
        </div>
    )
};

export default memo(EditAvatar);