import React, { memo, useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import { List, ListItem, Collapse, Icon, ListItemText, Badge } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { MenuContext } from "../../../../context/MenuContext";
import "./index.scss";


const NavItem = ({ item }) => {
    const [open, setOpen] = useState(false);
    const { toggleMenu } = useContext(MenuContext);

    return !item.childrens ?
        <ListItem
            component="li"
            className="navigation__item"
        >
            <NavLink className="navigation__link" to={item.path} exact={item.exact} onClick={toggleMenu}>
                {item.icon && (
                    <Icon className="navigation__icon">
                        {item.icon}
                    </Icon>
                )}

                <ListItemText
                    className="navigation__text"
                    primary={item.name}
                />

                {item.badge && <Badge badgeContent={item.badge} max={99} color="error" />}
            </NavLink>
        </ListItem> :
        <>
            <ListItem
                component="li"
                className={`navigation__dropdown ${open ? `_open` : ``}`}
                onClick={() => setOpen(!open)}
            >
                {item.icon && (
                    <Icon className="navigation__icon">
                        {item.icon}
                    </Icon>
                )}
                <ListItemText
                    className="navigation__text"
                    primary={item.name}
                />
                {item.badge && <Badge badgeContent={item.badge} max={99} color="error" />}
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <li className="navigation__item">
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="ul" disablePadding>
                        {item.childrens.map(children =>
                            <ListItem component="li" key={children.path}>
                                <NavLink className="navigation__link" to={children.path} exact={children.exact} onClick={toggleMenu}>
                                    <ListItemText
                                        className="navigation__text"
                                        primary={children.name}
                                    />

                                    {children.badge && <Badge badgeContent={children.badge} max={99} color="error" />}
                                </NavLink>
                            </ListItem>
                        )}
                    </List>
                </Collapse>
            </li>
        </>
};

export default memo(NavItem);