import React from "react";
import {CircularProgress} from "@material-ui/core";
import "./index.scss";


const Loading = ({centered = true}) => (
    <div className={`loading${centered ? ' centered' : ''}`}>
        <CircularProgress />
    </div>
);

export default Loading;