import React, {memo, useContext, useState} from "react";
import {SwipeableDrawer} from "@material-ui/core";
import {AuthContext} from "../../../../context/AuthContext";
import {MenuContext} from "../../../../context/MenuContext";
import SidebarContent from "./SidebarContent";
import useIsMobile from "../../../../hooks/useIsMobile";
import "./index.scss";


const Sidebar = () => {
    const [isPinned, setIsPinned] = useState(true);
    const {user} = useContext(AuthContext);
    const {isOpen, toggleMenu} = useContext(MenuContext);
    const isMobile = useIsMobile(1100);

    return !isMobile ?
        <div className={`user-layout__sidebar sidebar${isPinned ? ' _pinned' : ''}`}>
            <SidebarContent
                user={user}
                isPinned={isPinned}
                setIsPinned={setIsPinned}
            />
        </div> :
        <SwipeableDrawer
            className="sidebar-mobile"
            variant="temporary"
            anchor="left"
            open={isOpen}
            onClose={toggleMenu}
            onOpen={toggleMenu}
            ModalProps={{
                keepMounted: true
            }}
            disableBackdropTransition
        >
            <SidebarContent
                user={user}
                isMobile={true}
                toggleMenu={toggleMenu}
            />
        </SwipeableDrawer>
};

export default memo(Sidebar);