import {useCallback, useEffect, useState} from "react";
import useIsMobile from "../hooks/useIsMobile";


export const useToggleMenu = () => {
    const [isOpen, setIsOpen] = useState(false);
    const isMobile = useIsMobile(991);

    const toggleMenu = useCallback(() => {
        setIsOpen(!isOpen);
    }, [isOpen]);

    useEffect(() => {
        if(!isMobile) {
            setIsOpen(false);
        }
    }, [isMobile]);

    return {isOpen, toggleMenu};
};