import React, {memo} from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import AuthLayout from "../../components/Layouts/AuthLayot";
import {authRoutes} from "./routes";


const Auth = () => (
    <AuthLayout>
        <Switch>
            {!!authRoutes.length && authRoutes.map(route =>
                <Route
                    key={route.path}
                    exact={route.exact}
                    path={route.path}
                    component={route.component}
                />
            )}
            <Redirect to="/login" />
        </Switch>
    </AuthLayout>
);

export default memo(Auth);