import React, {memo} from "react";
import ScrollArea from "react-scrollbar";
import Sidebar from "./Sidebar";
import Header from "./Header";
import "./index.scss";


const UserLayout = ({children}) => (
    <div className="user-layout">
        <Sidebar/>
        <div className="user-layout__main">
            <Header/>
            <ScrollArea
                className="user-layout__content"
                contentClassName="user-layout__scroll"
                verticalScrollbarStyle={{borderRadius: 6, width: 6, backgroundColor: '#aaa'}}
                verticalContainerStyle={{borderRadius: 8, width: 8}}
                horizontal={false}
                smoothScrolling={true}
            >
                {children}
            </ScrollArea>
        </div>
    </div>
);

export default memo(UserLayout);