import React from "react";
import ScrollArea from "react-scrollbar";
import "./index.scss";


const AuthLayout = ({children}) => (
    <ScrollArea
        className="auth"
        contentClassName="auth-layout"
        verticalScrollbarStyle={{borderRadius: 6, width: 6, backgroundColor: '#aaa'}}
        verticalContainerStyle={{borderRadius: 8, width: 8}}
        horizontal={false}
        smoothScrolling={true}
    >
        <div className="auth-layout__content">
            <div className="auth-layout__content-left">
                {children}
            </div>
            <div className="auth-layout__content-right">
                <h1 className="auth-layout__title">Добро пожаловать в систему онлайн-записи и работы с клиентами</h1>
                <p className="auth-layout__subtitle">Настройте запись клиентов на Ваши услуги и управляйте клиентской базой быстро и просто.</p>
            </div>
        </div>
    </ScrollArea>
);

export default AuthLayout;