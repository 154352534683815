import * as LoadableModules from "../../appModules";
const isBlocked = JSON.parse(localStorage.getItem('user'))?.state === '2798321a-0007-476f-b999-4155572b7f36';

//if provider is blocked you have you show subscription and personal info only

export const organizationRoutes = isBlocked ? [
    {
        exact: true,
        path: '/',
        component: LoadableModules.LoadableProfile
    }
] : [
    {
        exact: true,
        path: '/',
        component: LoadableModules.LoadableProfile
    },
    {
        exact: true,
        path: '/schedule',
        component: LoadableModules.LoadableSchedule
    },
    {
        exact: true,
        path: '/staff',
        component: LoadableModules.LoadableStaff
    },
    {
        exact: true,
        path: '/clients',
        component: LoadableModules.LoadableClients
    },
    {
        exact: true,
        path: '/analytics',
        component: LoadableModules.LoadableAnalytics
    },
    {
        exact: true,
        path: '/service/add',
        component: LoadableModules.LoadableServiceAdd
    },
    {
        exact: true,
        path: '/service/catalog',
        component: LoadableModules.LoadableServiceCatalog
    },
    {
        exact: true,
        path: '/service/widgets',
        component: LoadableModules.LoadableServiceWidgets
    },
    {
        exact: true,
        path: '/service/widgets/add',
        component: LoadableModules.LoadableServiceWidget
    },
    {
        exact: true,
        path: '/service/widgets/edit/:id',
        component: LoadableModules.LoadableServiceWidget
    },
    {
        exact: true,
        path: '/service/:id',
        component: LoadableModules.LoadableServiceAdd
    },
    {
        exact: true,
        path: '/trash',
        component: LoadableModules.LoadableTrash
    }
];

export const clientRoutes = [
    {
        exact: true,
        path: '/',
        component: LoadableModules.LoadableProfile
    },
    {
        exact: true,
        path: '/services',
        component: LoadableModules.LoadableClientServices
    }
];