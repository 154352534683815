import React, {memo, useContext} from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import UserLayout from "../../components/Layouts/UserLayout";
import {AuthContext} from "../../context/AuthContext";
import {clientRoutes, organizationRoutes} from "./routes";


const User = () => {
    const {user} = useContext(AuthContext);
    const isClient = user.user_type === 2;
    const routes = isClient ? clientRoutes : organizationRoutes;

    return (
        <UserLayout>
            <Switch>
                {routes.map(route =>
                    <Route
                        key={route.path}
                        exact={route.exact}
                        path={route.path}
                        component={route.component}
                    />
                )}
                <Redirect to="/"/>
            </Switch>
        </UserLayout>
    )
};

export default memo(User);