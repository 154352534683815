import React, {memo} from "react";
import {BrowserRouter as Router, Switch} from "react-router-dom";
import {useAuth} from "./hooks/auth.hook";
import {AuthContext} from "./context/AuthContext";
import {useToggleMenu} from "./hooks/toggleMenu.hook";
import {MenuContext} from "./context/MenuContext";
import Loading from "./components/Loading";
import User from "./pages/User";
import Auth from "./pages/Auth";


const App = () => {
    const {token, user, login, logout, ready} = useAuth();
    const isAuthenticated = !!token && !!user;
    const {isOpen, toggleMenu} = useToggleMenu();

    return !ready ?
        <Loading/> :
        <AuthContext.Provider value={{token, user, login, logout}}>
            <Router>
                <Switch>
                    {!isAuthenticated ?
                        <Auth /> :
                        <MenuContext.Provider value={{isOpen, toggleMenu}}>
                            <User />
                        </MenuContext.Provider>
                    }
                </Switch>
            </Router>
        </AuthContext.Provider>
};

export default memo(App);