import React, { memo, useState } from "react";
import { List } from "@material-ui/core";
import { nav } from "./config";
import NavItem from "./NavItem";
import "./index.scss";

//provider status
//blocked 2798321a-0007-476f-b999-4155572b7f36
//free 370f2911-2609-45a0-9232-5dd0e43da91f

const Navigation = ({ isClient }) => {
    const [isBlocked] = useState(JSON.parse(localStorage.getItem('user'))?.state === '2798321a-0007-476f-b999-4155572b7f36');

    return (
        <List
            component="ul"
            className="navigation"
        >
            {nav(isClient, isBlocked).map((item, i) =>
                <NavItem item={item} key={item.path || i} />
            )}
        </List>
    )
};

export default memo(Navigation);