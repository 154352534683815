export const nav = (isClient, isBlocked) => {
    let menu = [
        {
            path: `/`,
            name: 'Личный кабинет',
            icon: 'person',
            exact: true
        }
    ];
    //show menu for non blocked provider
    if (!isClient && !isBlocked) {
        menu = [
            ...menu,
            {
                path: `/schedule`,
                name: 'Расписание',
                icon: 'today',
                exact: true
            },
            {
                name: 'Услуги',
                icon: 'apps',
                childrens: [
                    {
                        path: '/service/add',
                        name: 'Создание услуги',
                        exact: true
                    },
                    {
                        path: '/service/catalog',
                        name: 'Каталог услуг',
                        exact: true,
                        // badge: 33
                    }
                ]
            },
            {
                name: 'Виджеты',
                icon: 'call_to_action',
                childrens: [
                    {
                        path: '/service/widgets/add',
                        name: 'Создание виджета',
                        exact: true
                    },
                    {
                        path: '/service/widgets',
                        name: 'Каталог виджетов',
                        exact: true,
                        // badge: 33
                    }
                ]
            },
            {
                path: `/staff`,
                name: 'Сотрудники',
                icon: 'people_alt',
                exact: true
            },
            {
                path: `/clients`,
                name: 'Клиенты',
                icon: 'person_pin',
                exact: true
            },
            {
                path: `/analytics`,
                name: 'Аналитика',
                icon: 'assessment',
                exact: true
            },
            {
                path: `/trash`,
                name: 'Корзина',
                icon: 'delete',
                exact: true
            }
        ];
        //show menu for user
    } else if (isClient) {
        menu = [
            ...menu,
            {
                path: `/services`,
                name: 'Расписание',
                icon: 'today',
                exact: true
            }
        ];
        //show menu for blocked provider
    } else if (!isClient && isBlocked) {
        menu = [...menu]
    }

    return menu;
};