import React, {useEffect} from "react";
import ReactDOM from "react-dom";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import App from "./App";
import {widgetsRoutes} from "./pages/Widgets/routes";
import "./index.scss";


const Application = () => {
    useEffect(() => {
        if(!process || !process.env || process.env.NODE_ENV !== 'development') {
            //Яндекс метрика
            let yMetrika = document.createElement('script');
            yMetrika.innerHTML = `
                (function (m, e, t, r, i, k, a) {
                  m[i] = m[i] || function () { (m[i].a = m[i].a || []).push(arguments) };
                  m[i].l = 1 * new Date(); k = e.createElement(t), a = e.getElementsByTagName(t)[0], k.async = 1, k.src = r, a.parentNode.insertBefore(k, a)
                })(window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
                ym(78915943, "init", {
                  clickmap: true,
                  trackLinks: true,
                  accurateTrackBounce: true,
                  webvisor: true
                });
            `;
            document.body.append(yMetrika);

            //Google аналитика
            // const gAnalyticsSrc = document.createElement('script');
            // gAnalyticsSrc.src = 'https://www.googletagmanager.com/gtag/js?id=G-1E949PESJW';
            // document.body.append(gAnalyticsSrc);
            //
            // let gAnalytics = document.createElement('script');
            // gAnalytics.innerHTML = `
            //     window.dataLayer = window.dataLayer || [];
            //     function gtag() { dataLayer.push(arguments); }
            //     gtag('js', new Date());
            //     gtag('config', 'G-1E949PESJW');
            // `;
            // document.body.append(gAnalytics);
        }
    }, []);

    return (
        <Router>
            <Switch>
                {widgetsRoutes.map(route =>
                    <Route
                        key={route.path}
                        exact={route.exact}
                        path={route.path}
                        component={route.component}
                    />
                )}
                <App/>
            </Switch>
        </Router>
    )
};

ReactDOM.render(<Application />, document.getElementById('root'));