import React, { memo } from "react";
import LightTooltip from "../../../LigthTooltip";
import { Icon, IconButton } from "@material-ui/core";
import ScrollArea from "react-scrollbar";
import Navigation from "../Navigation";
import EditAvatar from "./EditAvatar";


const SidebarContent = ({ user, isMobile = false, isPinned, setIsPinned, toggleMenu }) => (
    <div className="sidebar__content">
        <div className="sidebar__head">
            <img className="sidebar__logo" src="/assets/images/default/logo.svg" alt="logo" />
            <LightTooltip title={isPinned ? 'Открепить' : 'Закрепить'}>
                {isMobile ?
                    <IconButton
                        className="sidebar__close-btn"
                        onClick={toggleMenu}
                    >
                        <Icon>close</Icon>
                    </IconButton> :
                    <IconButton
                        className={`sidebar__pin-btn${isPinned ? ' _pinned' : ''}`}
                        onClick={() => setIsPinned(!isPinned)}
                    >
                        <Icon>push_pin</Icon>
                    </IconButton>
                }
            </LightTooltip>
        </div>
        <ScrollArea
            className="sidebar__scroll"
            contentClassName="sidebar__scroll-content"
            verticalScrollbarStyle={{ borderRadius: 6, width: 6, backgroundColor: '#aaa' }}
            verticalContainerStyle={{ borderRadius: 8, width: 8 }}
            horizontal={false}
            smoothScrolling={true}
        >
            <div className="sidebar__user">
                <LightTooltip title={user.name}><p className="sidebar__user-name">{user.name}</p></LightTooltip>
                <LightTooltip title={user.email}><p className="sidebar__user-email">{user.email}</p></LightTooltip>
                <EditAvatar avatar={user.avatar_link} />
            </div>
            <div className="sidebar__navigation">
                <Navigation isClient={user.user_type === 2} />
            </div>
        </ScrollArea>
    </div>
);

export default memo(SidebarContent);