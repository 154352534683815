import React, {memo, useContext, useState} from "react";
import {Link} from "react-router-dom";
import {IconButton, Menu, MenuItem, ListItemIcon, ListItemText} from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import {AccountCircleOutlined, ExitToAppOutlined} from "@material-ui/icons";
import {AuthContext} from "../../../../../context/AuthContext";
import {DEFAULT_AVATAR} from "../../../../../appConfig";
import "./index.scss";

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
));


const UserAuth = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const {user, logout} = useContext(AuthContext);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className="header__user-auth user-auth">
            <IconButton className="user-auth__btn" onClick={handleClick}>
                <img src={user.avatar_link || DEFAULT_AVATAR} alt="avatar" className="user-auth__avatar"/>
            </IconButton>
            <StyledMenu
                id="user-auth"
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={handleClose}
            >
                <MenuItem>
                    <Link to="/" className="user-auth__menu-link">
                        <ListItemIcon>
                            <AccountCircleOutlined fontSize="default" />
                        </ListItemIcon>
                        <ListItemText primary="Личный кабинет" />
                    </Link>
                </MenuItem>
                <MenuItem onClick={logout}>
                    <ListItemIcon>
                        <ExitToAppOutlined fontSize="default" />
                    </ListItemIcon>
                    <ListItemText primary="Выйти" />
                </MenuItem>
            </StyledMenu>
        </div>
    )
};

export default memo(UserAuth);