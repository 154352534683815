import React, { memo, useContext, useRef, useState } from "react";
import Dropzone from "react-dropzone";
import AvatarEditor from "react-avatar-editor";
import { Icon, IconButton, Slider } from "@material-ui/core";
import LightTooltip from "../../../../../components/LigthTooltip";
import CustomButton from "../../../../../components/CustomButton";
import CustomAlert from "../../../../../components/Alert";
import { useRequest } from "../../../../../hooks/request.hook";
import { UPLOAD_AVATAR } from "../../../../../appConfig";
import { AuthContext } from "../../../../../context/AuthContext";
import useIsMobile from "../../../../../hooks/useIsMobile";
import { useConfirm } from "../../../../../hooks/confirm.hook";
import "./index.scss";
import Loading from "../../../../Loading";


const CustomAvatarEditor = ({ avatar, closeModal }) => {
    const [image, setImage] = useState(avatar || '');
    const [position, setPosition] = useState({ x: 0.5, y: 0.5 });
    const [scale, setScale] = useState(1);
    const [rotate, setRotate] = useState(0);
    const [editorErrors, setEditorErrors] = useState([]);
    const editor = useRef(null);
    const { request, loading, errors, clearError } = useRequest();
    const { user, token, login } = useContext(AuthContext);
    const isClient = user.user_type === 2;
    const isMobile = useIsMobile(600);
    const { confirm } = useConfirm();

    if (errors.length) {
        setEditorErrors([...editorErrors, ...errors]);
    }

    const handleSubmit = () => {
        request({
            url: isClient ? '/api/client/avatar/base64' : '/api/provider/avatar/base64',
            method: 'PUT',
            data: {
                data: editor.current.getImageScaledToCanvas().toDataURL('image/jpeg', 1)
            }
        }).then(data => {
            if (data) {
                login(token, {
                    ...user,
                    avatar_link: data
                });
                closeModal();
            }
        });
    };

    const handleAvatarDelete = async () => {
        const title = `Удаление аватара`;
        const confirmation = `Вы уверены, что хотите удалить аватар?`;

        if (await confirm(confirmation, title)) {
            setImage('');
            await request({
                url: isClient ? '/api/client/avatar' : '/api/provider/avatar',
                method: 'DELETE',
            }).then(() => {
                closeModal();
                login(token, {
                    ...user,
                    avatar_link: null
                });
            });
        }
    };

    const handleError = () => {
        setEditorErrors([
            ...editorErrors,
            'Ошибка: Формат файла не поддерживается, либо размер файла превышает 5Мб'
        ]);
    };

    const handleCloseAlert = () => {
        clearError();
        setEditorErrors([]);
    };

    return (
        <div className="avatar-editor">
            {loading ?
                <Loading centered={false} /> :
                <>
                    <div className="avatar-editor__dropzone">
                        <Dropzone
                            accept={['.jpg', '.jpeg', '.png']}
                            maxSize={5242880} //5MB
                            noClick
                            multiple={false}
                            onDrop={acceptedFiles => setImage(acceptedFiles[0])}
                            onDropRejected={handleError}
                        >
                            {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps()}>
                                    <AvatarEditor
                                        ref={editor}
                                        scale={parseFloat(scale)}
                                        width={isMobile ? 180 : 300}
                                        height={isMobile ? 180 : 300}
                                        position={position}
                                        onPositionChange={pos => setPosition(pos)}
                                        rotate={parseFloat(rotate)}
                                        borderRadius={150}
                                        image={image}
                                        className="avatar-editor__canvas"
                                        style={{ background: `url(${UPLOAD_AVATAR}) no-repeat center / cover` }}
                                    />
                                    <div className="avatar-editor__add-file">
                                        <label htmlFor="avatar" className="avatar-editor__add-label">
                                            <LightTooltip title="Добавить файл">
                                                <Icon>add</Icon>
                                            </LightTooltip>
                                        </label>
                                        <input
                                            name="avatar"
                                            id="avatar"
                                            className="avatar-editor__add-input"
                                            type="file"
                                            onChange={e => setImage(e.target.files[0])}
                                            {...getInputProps()}
                                        />
                                    </div>
                                </div>
                            )}
                        </Dropzone>
                    </div>
                    <div className="avatar-editor__controls">
                        <LightTooltip title="Масштабировать">
                            <Slider
                                className="avatar-editor__scale-bar"
                                min={1}
                                max={2}
                                step={0.01}
                                value={scale}
                                onChange={(e, newValue) => setScale(newValue)}
                                disabled={!image}
                            />
                        </LightTooltip>
                        <LightTooltip title="Повернуть">
                            <IconButton className="avatar-editor__btn" onClick={() => setRotate(rotate - 90)} disabled={!image}>
                                <Icon>rotate_left</Icon>
                            </IconButton>
                        </LightTooltip>
                        <LightTooltip title="Повернуть">
                            <IconButton className="avatar-editor__btn" onClick={() => setRotate(rotate + 90)} disabled={!image}>
                                <Icon>rotate_right</Icon>
                            </IconButton>
                        </LightTooltip>
                    </div>
                    <div className="avatar-editor__buttons">
                        <CustomButton
                            className="avatar-editor__submit"
                            disabled={!image}
                            onClick={handleSubmit}
                        >
                            Сохранить
                        </CustomButton>
                        <CustomButton
                            className="avatar-editor__delete"
                            bgColor="red"
                            disabled={!image}
                            onClick={handleAvatarDelete}
                        >
                            Удалить
                    </CustomButton>
                    </div>
                </>
            }
            {!!editorErrors.length &&
                editorErrors.map((error, i) =>
                    <CustomAlert
                        key={`error-${i}`}
                        message={error}
                        severity="error"
                        onClose={handleCloseAlert}
                    />
                )
            }
        </div>
    )
};

export default memo(CustomAvatarEditor);