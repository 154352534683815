import React, {memo} from "react";
import {AppBar, Dialog, DialogActions, DialogContent, Toolbar} from "@material-ui/core";
import "./index.scss";


const Modal = ({open, onClose, title, dialogActions, className, contentClassName, children, ...props}) => (
    <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth="xs"
        className={`modal${className ? ' ' + className : ''}`}
        {...props}
    >
        <AppBar position="static" className="modal__top">
            <Toolbar className="modal__title">
                {title}
            </Toolbar>
        </AppBar>
        <DialogContent className={`modal__content${contentClassName ? ' ' + contentClassName : ''}`} id="modal-content">
            {children}
        </DialogContent>
        {dialogActions &&
            <DialogActions>
                {dialogActions}
            </DialogActions>
        }
    </Dialog>
);

export default memo(Modal);