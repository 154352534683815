import Loadable from "react-loadable";
import Loading from "./components/Loading";

export const LoadableLogin = Loadable({
    loader: () => import('./pages/Auth/Login'),
    loading: Loading
});

export const LoadableRegistration = Loadable({
    loader: () => import('./pages/Auth/Registration'),
    loading: Loading
});

export const LoadableRecovery = Loadable({
    loader: () => import('./pages/Auth/PasswordRecovery'),
    loading: Loading
});

export const LoadableProfile = Loadable({
    loader: () => import('./pages/User/Profile'),
    loading: Loading
});

export const LoadableSchedule = Loadable({
    loader: () => import('./pages/User/Schedule'),
    loading: Loading
});

export const LoadableStaff = Loadable({
    loader: () => import('./pages/User/Staff'),
    loading: Loading
});

export const LoadableClients = Loadable({
    loader: () => import('./pages/User/Clients'),
    loading: Loading
});

export const LoadableAnalytics = Loadable({
    loader: () => import('./pages/User/Analytics'),
    loading: Loading
});

export const LoadableServiceAdd = Loadable({
    loader: () => import('./pages/User/Service'),
    loading: Loading
});

export const LoadableTrash = Loadable({
    loader: () => import('./pages/User/Trash'),
    loading: Loading
});

export const LoadableServiceCatalog = Loadable({
    loader: () => import('./pages/User/ServiceCatalog'),
    loading: Loading
});

export const LoadableServiceWidgets = Loadable({
    loader: () => import('./pages/User/ServiceWidgets'),
    loading: Loading
});

export const LoadableRegistrationForService = Loadable({
    loader: () => import('./pages/Widgets/RegistrationForService'),
    loading: Loading
});

export const LoadableUnsubscribeSuccess = Loadable({
    loader: () => import('./pages/Widgets/Unsubscribe/Success'),
    loading: Loading
});

export const LoadableUnsubscribeError = Loadable({
    loader: () => import('./pages/Widgets/Unsubscribe/Error'),
    loading: Loading
});

export const LoadableClientServices = Loadable({
    loader: () => import('./pages/User/ClientServices'),
    loading: Loading
});

export const LoadableServiceWidget = Loadable({
    loader: () => import('./pages/User/ServiceWidget'),
    loading: Loading
});