import React, {memo, useContext} from "react";
import UserAuth from "./UserAuth";
import {Icon, IconButton} from "@material-ui/core";
import {MenuContext} from "../../../../context/MenuContext";
import useIsMobile from "../../../../hooks/useIsMobile";
import LightTooltip from "../../../LigthTooltip";
import "./index.scss";


const Header = () => {
    const {toggleMenu} = useContext(MenuContext);
    const isMobile = useIsMobile(1100);
    return (
        <header className="user-layout__header header">
            {isMobile &&
                <IconButton
                    className="header__menu-btn"
                    onClick={toggleMenu}
                >
                    <Icon>menu</Icon>
                </IconButton>
            }
            <div className="header__wrapper">
              <div className='header__icon-section'> 
                <div className="header__button">
                  <LightTooltip title="Раздел в разработке">
                    <IconButton className="header__icons">
                      <Icon>notifications_none</Icon> 
                    </IconButton> 
                  </LightTooltip>
                </div>
                <span className="header__icons__title">Уведомления</span>
              </div>
              <div className='header__icon-section'> 
                <div className="header__button">
                  <LightTooltip title="Раздел в разработке">
                    <IconButton className="header__icons">
                      <Icon>bookmark_border_icon</Icon> 
                    </IconButton> 
                  </LightTooltip>
                </div>
                <span className="header__icons__title">Закладки</span>
              </div>
              <div className='header__icon-section'> 
                <div className="header__button">
                  <LightTooltip title="Раздел в разработке">
                    <IconButton className="header__icons">
                      <Icon>search</Icon> 
                    </IconButton> 
                  </LightTooltip>
                </div>
                <span className="header__icons__title">Поиск</span>
              </div>
              <div className='header__icon-section'> 
                <div className="header__button">
                  <LightTooltip title="Раздел в разработке">
                    <IconButton className="header__icons">
                      <Icon>fullscreen</Icon> 
                    </IconButton> 
                  </LightTooltip>
                </div>
                <span className="header__icons__title">Развернуть</span>
              </div>
              <div className='header__icon-section'> 
                <div className="header__button">
                  <LightTooltip title="Раздел в разработке">
                    <IconButton className="header__icons">
                      <Icon>language</Icon> 
                    </IconButton> 
                  </LightTooltip>
                </div>
                <span className="header__icons__title">Язык</span>
              </div>
            <div className="header__left">
                <UserAuth/>
            </div>
            </div>
        </header>
    )
};

export default memo(Header);