import * as LoadableModules from "../../appModules";


export const authRoutes = [
    {
        exact: true,
        path: '/login',
        component: LoadableModules.LoadableLogin
    },
    {
        exact: true,
        path: '/registration',
        component: LoadableModules.LoadableRegistration
    },
    {
        exact: true,
        path: '/recovery',
        component: LoadableModules.LoadableRecovery
    }
];