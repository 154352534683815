import React, {memo, useState} from "react";
import {Snackbar, IconButton, Icon} from "@material-ui/core";
import {Alert} from "@material-ui/lab";


//severity = 'error', 'success'

const CustomAlert = ({message, severity, autoClose = true, onClose}) => {
    const [open, setOpen] = useState(true);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);

        onClose();
    };

    return (
        <Snackbar
            open={open}
            autoHideDuration={autoClose ? 6000 : null}
            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
            onClose={handleClose}
        >
            <Alert
                variant="filled"
                severity={severity}
                action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={handleClose}
                    >
                        <Icon fontSize="small">close</Icon>
                    </IconButton>
                }
            >
                {message}
            </Alert>
        </Snackbar>
    )
};

export default memo(CustomAlert);