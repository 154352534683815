import React from "react";
import { Dialog, Toolbar, DialogContent, DialogActions, Button, AppBar } from "@material-ui/core";
import CustomButton from "../CustomButton";
import "./index.scss";


const CustomConfirm = ({ open, title, confirmation, onSuccess, onCancel }) => (
    <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        className="custom-confirm"
        open={open}
    >
        <AppBar position="static" className="custom-confirm__top">
            <Toolbar className="custom-confirm__title">
                {title}
            </Toolbar>
        </AppBar>
        {confirmation &&
            <DialogContent className="custom-confirm__content">
                {confirmation}
            </DialogContent>
        }
        <DialogActions className="custom-confirm__controls">
            <CustomButton onClick={onSuccess}>Ок</CustomButton>
            <Button onClick={onCancel} variant="contained">Отмена</Button>
        </DialogActions>
    </Dialog>
);

export default CustomConfirm;