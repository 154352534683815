import {useCallback, useEffect, useState} from "react";


export const useAuth = () => {
    const [token, setToken] = useState(null);
    const [user, setUser] = useState(null);
    const [ready, setReady] = useState(false);

    const login = useCallback((jwtToken, userData) => {
        localStorage.setItem('token', JSON.stringify(jwtToken));
        localStorage.setItem('user', JSON.stringify(userData));

        setToken(jwtToken);
        setUser(userData);
    }, []);

    const logout = useCallback(() => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');

        setToken(null);
        setUser(null);
    }, []);

    useEffect(() => {
        const localToken = JSON.parse(localStorage.getItem('token'));
        const localUser = JSON.parse(localStorage.getItem('user'));

        if(localToken && localUser) {
            login(localToken, localUser);
        }

        setReady(true);
    }, [login]);

    return {login, logout, token, user, ready};
};